import React from "react";
import './Home.css'
import { Link } from "react-router-dom";
const Home = () => {
  return(
    <div>
      <div className="headerComp">
        <div className="container-lg">
          <div className="logoMask">
          <Link to="/">
            <img src="../../images/logo.png" alt="Logo" />
          </Link>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="bannerInfo">
                <h2>We collect, clean, and
                deliver your laundry and
                dry cleaning.</h2>
                <p>Don’t leave your shopping to the last minute. The more time you have to shop around, the more you can negotiate. </p>
                <div className="bannerButton">
                <button type="button" class="btn btn-primary appStoreBtn">&nbsp;</button>
                <button type="button" class="btn btn-primary googlePlayBtn">&nbsp;</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="banneriPhone">
              <img src="../../images/iPhone-13-Pro-Front.png" alt="back arrow" className="img-fluid"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="serviceComp">
        <div className="container-lg">
          <div className="row">
              <div className="Title">Services</div>
              <div className="col-12 col-lg-6">
                <div className="serviceImg">
                  <img src="../../images/serviceImage.svg" alt="ServicesImage" className="img-fluid"/>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="serviceInfo">
                  <div className="serviceInfoTiles">
                    <div className="serviceInfoImage">
                      <img src="../../images/foldingCloths.svg" alt="ClothsImage" className="img-fluid"/>
                    </div>
                    <div className="serviceInfoDesc">
                      <h2>Dry Cleaning</h2>
                      <p>Cum et convallis risus placerat aliquam, nunc. Scelerisque aliquet faucibus tincidunt eu adipiscing sociis arcu lorem porttitor.</p>
                    </div>
                  </div>
                  <div className="serviceInfoTiles">
                    <div className="serviceInfoImage">
                      <img src="../images/ironCloths.svg" alt="InfoImage" className="img-fluid"/>
                    </div>
                    <div className="serviceInfoDesc">
                      <h2>Ironing</h2>
                      <p>Cum et convallis risus placerat aliquam, nunc. Scelerisque aliquet faucibus tincidunt eu adipiscing sociis arcu lorem porttitor.</p>
                    </div>
                  </div>
                  <div className="serviceInfoTiles">
                    <div className="serviceInfoImage">
                      <img src="../../images/washingMachine.svg" alt="MachineImage" className="img-fluid"/>
                    </div>
                    <div className="serviceInfoDesc">
                      <h2>Wash & Ironing</h2>
                      <p>Cum et convallis risus placerat aliquam, nunc. Scelerisque aliquet faucibus tincidunt eu adipiscing sociis arcu lorem porttitor.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className="userSayComp">
        <div className="container-lg">
          <div className="row">
              <div className="col-12 col-lg-12">
              <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="false">
              <h2>What Our Users Say About Us?</h2>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged</p>
                  <img src="../../images/avatar.png" alt="Avatar" className="img-fluid"/>
                  <span>nick jonas</span>
                </div>
                <div className="carousel-item">
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged</p>
                  <img src="../../images/avatar.png" alt="Avatar" className="img-fluid"/>
                  <span>nick jonas</span>
                </div>
                <div className="carousel-item">
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged</p>
                  <img src="../../images/avatar.png" alt="Avatar" className="img-fluid"/>
                  <span>nick jonas</span>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
              </div>
          </div>
        </div>
      </div>
      <div className="getInTuch">
        <div className="container-lg">
          <div className="getInTuchMask">
            <div className="titleSubtitle">
              <h2>Get In Touch</h2>
              <p>Our friendly team would have to hear from you!</p>
            </div>
            <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="form-floating mb-3">
                    <input type="text" className="form-control" id="floatingInputName" placeholder="First Name"/>
                    <label for="floatingInput">First Name</label>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="form-floating">
                    <input type="text" className="form-control" id="floatingInputLast" placeholder="Last name"/>
                    <label for="floatingPassword">Last name</label>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="form-floating mb-3">
                    <input type="email" className="form-control" id="floatingInputEmail" placeholder="Email"/>
                    <label for="floatingInput">Email</label>
                  </div>
                </div>


                <div className="col-12 col-lg-6">
                  <div class="input-group mb-3">
                  <div className="col-3 col-lg-3">
                      <select class="form-select" aria-label="Default select example">
                        <option selected>+91</option>
                        <option value="81">+81</option>
                        <option value="55">+55</option>
                        <option value="21">+21</option>
                      </select>
                    </div>
                    <div className="form-floating">
                        <input type="text" className="form-control" id="floatingInputPhone" placeholder="Phone Number"/>
                        <label for="floatingPassword">Phone Number</label>
                      </div>
                  </div>
                </div>
                <div className="col-12 col-lg-12">
                  <div class="form-floating">
                    <textarea class="form-control customTextarea" placeholder="How can we help?" id="floatingTextarea"></textarea>
                    <label for="floatingTextarea">How can we help?</label>
                  </div>
                </div>
                <div className="col-12 col-lg-12">
                  <button type="button" class="btn btn-primary sendMessagebtn">Send Message</button>
                </div>
            </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Home;