import React, {useEffect, useState} from 'react';
import './Deletion.css';
//import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Deletion = () => {

  const [firstname, setFirstname] = React.useState('');
  const [lastname, setLastname] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [contact, setContact] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [thanksmessage, setThanksmessage] = React.useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    const requestData = {
      "firstname": firstname,
      "lastname":lastname,
      "email":email,
      "contact":contact,
      "description":description,
  }



    axios.post(`https://washton.in/Admin/public/api/delete_request`, requestData)
    .then(res => {
       console.log(res.data);
       setThanksmessage('Thanks you.We have received your request, and we will clear your data from our system');
       setFirstname('');
       setLastname('');
       setEmail('');
       setContact('');
       setDescription('');
    });

  }



  return (
    <>

    <div className='cmsPaesWrapper'>
    <div className="headerComp">
      <div className="container-lg">
        <div className="logoMask">
          <Link to="/">
            <img src="../../images/logo.png" alt="Logo" />
          </Link>
        </div>
      </div>
    </div>
     <div className="getInTuch">
        <div className="container-lg">
          <div className="getInTuchMask">
            <div className="titleSubtitle">
              <h1>Request Data Deletion</h1>
              <p>Our friendly team would have to hear from you!</p>
            </div>
            <span style={{ color:'green'}}>{thanksmessage}</span>
            <br/>
            <br/>
            <form onSubmit={handleSubmit}>
              <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="form-floating mb-3">
                      <input type="text" className="form-control" id="floatingInputName" onChange={(e) => setFirstname(e.target.value)} value={firstname} placeholder="First Name" required/>
                      <label for="floatingInput">First Name</label>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="form-floating">
                      <input type="text" className="form-control" value={lastname} onChange={(e) => setLastname(e.target.value)}  id="floatingInputLast" placeholder="Last name" required/>
                      <label for="floatingPassword">Last name</label>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="form-floating mb-3">
                      <input type="email" className="form-control" onChange={(e) => setEmail(e.target.value)}  value={email} id="floatingInputEmail" placeholder="Email"/>
                      <label for="floatingInput">Email</label>
                    </div>
                  </div>


                  <div className="col-12 col-lg-6">
                    <div class="input-group mb-3">

                      <div className="form-floating">
                          <input type="text" className="form-control" id="floatingInputPhone" value={contact} onChange={(e) => setContact(e.target.value)}   placeholder="Phone Number" required/>
                          <label for="floatingPassword">Phone Number</label>
                        </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-12">
                    <div class="form-floating">
                      <textarea class="form-control customTextarea" placeholder="How can we help?" id="floatingTextarea" onChange={(e) => setDescription(e.target.value)} >{description}</textarea>
                      <label for="floatingTextarea">How can we help?</label>
                    </div>
                  </div>
                  <div className="col-12 col-lg-12">
                    <button type="submit" class="btn btn-primary sendMessagebtn" >Send Message</button>
                  </div>

              </div>
            </form>
            </div>
        </div>
      </div>

  </div>
  </>
  );
};

export default Deletion;