import React, { useEffect } from 'react';
import './Terms.css';
import { Link } from 'react-router-dom';
const Terms = () => {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <>
    <div className='cmsPaesWrapper'>
    <div className="headerComp">
      <div className="container-lg">
        <div className="logoMask">
        <Link to="/">
          <img src="../../images/logo.png" alt="Logo" />
        </Link>
        </div>
      </div>
    </div>
    <div className="container-lg">
    <h1>Terms and Conditions</h1>
     <p>Last updated: 28th Jan, 2024</p>
    <p>Thank you for choosing Washton for your laundry needs. By using our services, you agree to abide by the following terms and conditions:</p>
    <ol>
      <li>Service Agreement
        <ol>
          <li><span>Services Provided:</span> Washton  agrees to provide laundry services as described in the service agreement or as requested by the customer.</li>
          <li><span>Service Limitations:</span> Our services are subject to availability and may be limited by factors such as location, service hours, and capacity.</li>
        </ol>
      </li>
      <li>Customer Responsibilities
        <ol>
          <li><span>Proper Packaging:</span> Customers are responsible for properly packaging their laundry items and ensuring they comply with our guidelines for safe and efficient processing.</li>
        </ol>
      </li>
      <li>Pricing and Payments
        <ol>
          <li><span>Payment Obligations:</span> Customers agree to pay the agreed-upon fees for the laundry services provided. Prices are outlined in our pricing structure, which may be subject to change with notice.</li>
          <li><span>Billing:</span> Billing will occur as per the agreed-upon frequency (e.g., per load, weekly, monthly). Late payments may incur additional fees.</li>
        </ol>
      </li>
      <li>Service Quality and Damages
        <ol>
          <li><span>Quality Assurance:</span> Washton strives to provide high-quality services. If a customer is dissatisfied with the service, they must notify us within a reasonable time for resolution.</li>
          <li><span>Damaged Items:</span> While we take utmost care, Washton is not liable for damages to items that are improperly packaged or for damages caused by wear and tear.</li>
        </ol>
      </li>
      <li>Cancellations and Refunds
        <ol>
          <li><span>Cancellation Policy:</span> Customers may cancel scheduled services with advance notice. Late cancellations may be subject to fees.</li>
          <li><span>Refund Policy:</span> Refunds will be issued at the discretion of Washton and in accordance with our refund policy.</li>
        </ol>
      </li>
      <li>Privacy
        <ol>
          <li><span>Privacy Policy:</span> The collection and use of customer information are governed by our Privacy Policy. Please refer to the Privacy Policy for more details.</li>
        </ol>
      </li>
      <li>Changes to Terms and Conditions
        <ol>
          <li><span>Updates:</span> Washton reserves the right to update these terms and conditions. Customers will be notified of any changes, and continued use of our services constitutes acceptance of the updated terms.</li>
        </ol>
      </li>
      <li>Contact Information
        <p>For questions or concerns regarding these terms and conditions, please contact us at Vivek@washton.in</p>
      </li>
    </ol>
  </div>
  </div>
  </>
  );
};

export default Terms;