import React, { useEffect }  from 'react';
import './Policy.css';
//import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';
const Policy = () => {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <>

    <div className='cmsPaesWrapper'>
    <div className="headerComp">
      <div className="container-lg">
        <div className="logoMask">
          <Link to="/">
            <img src="../../images/logo.png" alt="Logo" />
          </Link>
        </div>
      </div>
    </div>
    <div className="container-lg">
    <h1>Privacy Policy</h1>
    <p>Last updated: 28th Jan, 2024</p>
    <p>Thank you for choosing Washton for your laundry needs. This Privacy Policy is designed to inform you about how we collect, use, and protect the personal information you provide to us. By using our services, you agree to the terms outlined in this policy.</p>
    <ol>
      <li>Information We Collect
        <p>We may collect the following types of personal information:</p>
        <ol>
          <li><span>Contact Information:</span> Name, address, email address, phone number.</li>
          <li><span>Payment Information:</span> Credit card details, billing address.</li>
          <li><span>Laundry Preferences:</span> Special instructions, fabric preferences, laundry history.</li>
          <li><span>Account Information:</span> Username, password.</li>
        </ol>
      </li>
      <li>How We Use Your Information
        <p>We use your personal information for the following purposes:</p>
        <ol>
          <li><span>Service Delivery:</span> Fulfilling your laundry service requests.</li>
          <li><span>Communication:</span> Sending service updates, promotions, and responding to your inquiries.</li>
          <li><span>Payment Processing:</span> Billing and collecting payments.</li>
          <li><span>Improving Services:</span> Analyzing customer preferences and enhancing our offerings.</li>
        </ol>
      </li>
      <li>Information Sharing
        <p>We do not sell, trade, or rent your personal information to third parties. However, we may share your information with:</p>
        <ol>
          <li><span>Service Providers:</span> Trusted partners who assist in delivering our services.</li>
          <li><span>Legal Compliance:</span>When required by law or to protect our rights and interests.</li>
        </ol>
      </li>
      <li>Data Security
        <p>We implement security measures to safeguard your personal information, but no method of transmission over the internet is 100% secure. We strive to protect your data, but we cannot guarantee its absolute security.</p>
      </li>
      <li>Your Choices
        <p>You have the right to:</p>
        <ol>
          <li><span>Access and Correct Your Information:</span> Review and update your personal details.</li>
          <li><span>Opt-out of Communications:</span> Choose not to receive promotional emails.</li>
        </ol>
      </li>
      <li>Children's Privacy
        <p>Our services are not directed towards individuals under the age of 18. We do not knowingly collect personal information from children.</p>
      </li>
      <li>Changes to this Privacy Policy
        <p>We reserve the right to update this Privacy Policy. Please check this page periodically for changes.</p>
      </li>
      <li>Contact Us
        <p>If you have any questions or concerns about our Privacy Policy, please contact us at Vivek@washton.in</p>
      </li>
    </ol>
    </div>
  </div>
  </>
  );
};

export default Policy;