import React from "react";
import './Footer.css'
import { Link } from "react-router-dom";
const Footer = () => {
  return(
    <div className="footer">
        <div className="container-lg">
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="footerMask">
                <img src="../../images/logo.png" alt="back arrow" className="img-fluid footerlogo"/>
                <ul className="socialLinks">
                  <li><Link to="https://www.google.com/" target="_blank"><img src="../../images/facebook.svg" alt="facebook" className="img-fluid"/></Link></li>
                  <li><Link to="#" target="_blank"><img src="../../images/instagram.svg" alt="instagram" className="img-fluid"/></Link></li>
                  <li><Link to="#" target="_blank"><img src="../../images/linkedin.svg" alt="linkedin" className="img-fluid"/></Link></li>
                  <li><Link to="#" target="_blank"><img src="../../images/twitter.svg" alt="twitter" className="img-fluid"/></Link></li>
                </ul>
                <div className="copyrightText">
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <p className="footerCopyright">Copyright 2023 WASHTON all rights reserved</p>
                    </div>
                    <div className="col-12 col-lg-6">
                      <ul className="footerLinks">
                        <li><Link to="/Terms">Terms & Conditions</Link></li>
                        <li><Link to="/Policy">Privacy Policy</Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}
export default Footer;