import React from 'react';
import Home from './Components/Home/Home';
import Terms from './Components/Terms/Terms';
import Policy from './Components/Policy/Policy';
import Deletion from './Components/Deletion/Deletion';
import Footer from './Components/Footer/Footer';
import {
  Routes,
  Route,
} from "react-router-dom";


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="index.html" element={<Home />} />
        <Route path="Terms" element={<Terms />} />
        <Route path="Policy" element={<Policy />} />
        <Route path="request-data-deletion" element={<Deletion />} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
